import { 
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  FormLabel,
  FormControl,
  FormErrorMessage,
  forwardRef,
} from "@chakra-ui/react";
import { ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";

interface SelectProps extends ChakraSelectProps {
  name: string;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  options: {
    value: string | number;
    label: string | number;
  }[];
}


const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = ({ name, label, placeholder, error = null, options, ...rest }, ref) => {
  return (
    <FormControl isInvalid={!!error}>
      { !!label && <FormLabel fontSize="2xl" fontWeight="bold" color="brand.900" htmlFor={name}>{label}</FormLabel> }

      <ChakraSelect
        id={name}
        name={name}
        placeholder={placeholder}
        color="brand.900"
        fontWeight="medium"
        borderColor={error ? "red.600" : "brand.900"}
        focusBorderColor={error ? "red.600" : "brand.600"}
        variant="filled"
        borderRadius="10"
        size="lg"
        bgColor="white"
        _placeholder={{
          background: "white"
        }}
        ref={ref}
        {...rest}
      >
        {
          options.map(option => (
            <option style={{
              backgroundColor: "white"
            }} value={option.value} key={option.value}>{option.label}</option>
          ))
        }
      </ChakraSelect>

      { !!error && (
        <FormErrorMessage>
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}

export const Select =  forwardRef(SelectBase)