import {
  ChakraProvider,
} from "@chakra-ui/react";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Home } from "./pages/Home";

import { theme } from "./styles/theme";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Home />
      <Footer />
    </ChakraProvider>
  )
}
