import { Flex, Heading, Link, Stack } from "@chakra-ui/react";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { SocialButton } from "./SocialButton";

export const Footer = () => (
  <Flex
    as="footer"
    w="100%"
    h="250px"
    bgColor="brand.900"
    justify="center"
    align="center"
  >
    <Flex
      w="100%"
      maxW={1280}
      justify="center"
      align="center"
      flexDirection="column"
    >
      <Link
        href="https://www.br.alcon.com/pt-br/contact-us"
        target="_blank"
        rel="noopener"
        color="white"
        fontSize="4xl"
        fontWeight="bold"
        _after={{
          content: `""`,
          display: "block",
          w: 0,
          h: "3px",
          bgColor: "brand.700",
          transition: "width 0.3s",
        }}
        _hover={{
          textDecoration: "none",
          _after: {
            w: "100%",
          }
        }}
      >
        Fale conosco
      </Link>

      <Stack spacing="4" direction="row" my="3">
        <SocialButton
          href="https://www.facebook.com/AlconEyeCare"
          icon={FaFacebookF} 
          title="Facebook Alcon"
        />
        <SocialButton
          href="https://www.linkedin.com/company/alcon/"
          icon={FaLinkedinIn} 
          title="Linkedin Alcon"
        />
        <SocialButton
          href="https://www.instagram.com/alconlife/"
          icon={FaInstagram} 
          title="Instagram Alcon"
        />
        <SocialButton
          href="https://www.youtube.com/channel/UCN_Zj_knZ6q85W8Oxl1nTUA/videos?disable_polymer=1"
          icon={FaYoutube} 
          title="Youtube Alcon"
        />
      </Stack>

      <Heading color="white">See Brilliantly</Heading>
    </Flex>
  </Flex>
)