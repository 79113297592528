import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

export const theme = extendTheme({
  config,
  colors: {
    brand: {
      900: "#143489",
      800: "#153e75",
      700: "#1a9ce6",
      600: "#2a69ac",
    },
    orange: {
      900: "#f05541"
    },
  },
  fonts: {
    heading: "Montserrat",
    body: "Montserrat"
  },
  styles: {
    global: {
      body: {
        bg: "white",
        color: "black"
      },
    },
  },
})