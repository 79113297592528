import { Flex, Image } from "@chakra-ui/react";

import logoAlcon from "../assets/alcon_white.png";

export const Header = () => (
  <Flex
    as="header"
    w="100%"
    h="100px"
    justify="center"
    align="center"
    bgColor="brand.900"
    boxShadow="dark-lg"
    px="8"
  >
    <Flex
      w="100%"
      maxW={1200}
      justify="center"
      align="center"
    >
      <Image maxW="150px" src={logoAlcon} alt="Alcon" />
    </Flex>    
  </Flex>
);